<template>
    <div class="work-card">
        <el-page-header @back="$router.go(-1)" :content="pageHeaderTitle"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!-- 标题 -->
                <div class="banner" :style="{ backgroundImage: `url(${backgroundImageUrl})` }">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="infoBox f-c-js" v-if="baseInfo">
                    <div style="position: relative;">
                       <el-avatar shape="circle" :size="120" fit="fill" :src="warkCardInfo.userAvatarUrl"></el-avatar>
                    <img class="sexImg" v-if="baseInfo[2].value == '男'" src="../../../assets/supply/man.png" alt="">
                    <img class="sexImg" v-if="baseInfo[2].value == '女'" src="../../../assets/supply/woman.png" alt=""> 
                    </div>
                    
                    <div class="Name_Iphone">
                        <div class="name">{{ baseInfo[1].value }}</div>
                        <div class="iphone">{{ baseInfo[3].value }}</div>
                    </div>
                    <div class="firm">{{ baseInfo[0].value }}</div>
                    <div class="section">{{ baseInfo[4].value }}</div>
                    <div class="flex editBtn" @click="dialogShow = true">
                        <img src="../../../assets/supply/edit.png" alt="">
                        <div class="text">编辑</div>
                    </div>
                </div>
                <div class="jurisdiction ">
                    <div class="f-c-b">
                        <div class="text">权限信息</div>
                        <div class="btn" @click="ShowjurisdictionList = !ShowjurisdictionList">
                            <span>查看权限</span><i
                                :class="ShowjurisdictionList == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                        </div>
                    </div>
                    <div v-show="ShowjurisdictionList" class="f-w list">
                        <div class="item flex" v-for="(i, idx) of powerInfo" :key="idx"
                            :style="{ borderBottom: (idx == 6 || idx == 7) ? 'none' : '' }">
                            <div class="title">{{ i.title }}：</div>
                            <div v-if="i.value" class="value">{{ i.value }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog title="修改工作牌" width="730px" :visible="dialogShow" @closeDialog="closeDialog">
            <div class="change-name">
                <el-form ref="form" :model="WorkForm" label-width="80px" :rules="workCardRules">
                    <el-form-item v-for="(item, index) of formArr" :label="item.label" :key="index"
                        :required="item.prop == 'name' ? true : false" style="text-align:left">
                        <!-- <div v-if="item.prop == 'name'" class="flex">
                            <span style="color: red;">*</span> -->
                        <ChineseCharactersInput v-if="item.isChineseCharactersInput" :modelValue="WorkForm[item.prop]"
                            :maxlength="'30'" @getValue="getValue" :type="item.prop">
                        </ChineseCharactersInput>
                        <!-- </div> -->

                        <el-radio-group v-if="item.prop == 'sex'" v-model="WorkForm[item.prop]">
                            <el-radio label="男">男</el-radio>
                            <el-radio label="女">女</el-radio>
                        </el-radio-group>
                        <PhoneInput v-if="item.isPhoneInput" :modelValue="WorkForm[item.prop]"
                            :placeholderValue="item.placeholderValue" @getPhoneNumber="getPhoneNumber" :LengthLimit="'16'"
                            @clearPhone="handlePhoneEmpty" :maxlength="16">
                        </PhoneInput>
                    </el-form-item>
                    <el-form-item class="f-c-c">
                        <el-button type="primary" class="modify-ok" @click.prevent="modifyWorkCard('form')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { UrlDecodeIdType } from '@/mixins/UrlDecodeMixin'
import { workCardRulesNoPhone, workCardRulesHasPhone } from '@/utils/FormRules'
export default {
    inject: ["reload"],
    mixins: [UrlDecodeIdType],
    name: 'WorkCard',
    components: { NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue') },
    data() {
        return {
            warkCardInfo: {},
            baseInfo: [],
            powerInfo: [],
            dialogShow: false,
            WorkForm: {
                name: '',
                sex: '',
                phone: '',
                department: ''
            },
            formArr: [{
                label: "姓名",
                prop: "name",
                isChineseCharactersInput: true
            }, {
                label: "性别",
                prop: "sex"
            }, {
                label: "手机号码",
                prop: "phone",
                isPhoneInput: true,
                placeholderValue: '请输入手机号码'
            }, {
                label: "所属部门",
                prop: "department",
                isChineseCharactersInput: true
            }],
            workCardRules: workCardRulesNoPhone,
            showModifyEnter: false,//是否显示修改入口
            showDeleteEnter: false,//是否显示删除入口
            showPowerList: false,//是否显示权限列表
            showPowerEnter: false,//是否显示修改权限入口
            hasData: true,//true-data为空 false-data不为空
            drawer: false,
            direction: 'rtl',
            p_l: '',
            c_m: '',
            e_i: '',
            o_m: '',
            a_m: '',
            f_s: '',
            i_m: '',
            p_m: '',
            drawerForm: {
                "a_m": "",//人员权限管理
                "c_m": "",//客户管理
                "e_i": "",//企业信息
                "f_s": "",//财务统计
                "i_m": "",//库存管理
                "o_m": "",//订单管理
                "p_c": "",//工作牌
                "p_l": "",//产品库
                "p_m": ""//人员管理
            },
            pageHeaderTitle: '',//页头标题
            activeNames: [],//折叠展开
            RadioButtonArr: [{
                lable: 'R',
                subTitle: '查看'
            }, {
                lable: 'W',
                subTitle: '查看/编辑'
            }, {
                lable: 'D',
                subTitle: '不可见'
            }],
            RadioButtonArr1: [{
                lable: 'R',
                subTitle: '查看'
            }, {
                lable: 'W',
                subTitle: '查看/编辑'
            }],
            drawerList: [{
                title: '产品库',
                prop: 'p_l',
            }, {
                title: '客户管理',
                prop: 'c_m',
            }, {
                title: '企业信息',
                prop: 'e_i',
            }, {
                title: '订单管理',
                prop: 'o_m',
            }, {
                title: '人员管理',
                prop: 'p_m',
            }, {
                title: '财务',
                prop: 'f_s',
            }, {
                title: '入库管理',
                prop: 'i_m',
            }, {
                title: '人员权限管理',
                prop: 'a_m',
            }],
            enterpriseStateInfo: {},//权限信息
            ShowjurisdictionList: false,
            isPreviewVisible: false,
            backgroundImageUrl:'',
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
        let that = this;
        that.pageHeaderTitle = '工作牌'
        that.showModifyEnter = true;
        that.showPowerList = true;
        that.showPowerEnter = false;
        that.userFindNameCard()

    },
    methods: {
        getPhoneNumber(data) {
            this.WorkForm.phone = data.value
        },
        // 刷新当前页面
        refresh() {
            this.reload();
        },
        getValue(data) {
            this.WorkForm[data.type] = data.value
        },
        // 用户查看工作牌接口
        userFindNameCard() {
            let that = this;
            that.$http.userFindNameCard({
                userId: that.$store.state.userId,
                token: that.$getStorage('token')
            }).then(res => {
                var hasData = (JSON.stringify(res.data) == "{}");
                that.hasData = hasData;
                if (!hasData && res.code == 200) {
                    // a_m 人员权限管理  c_m  客户管理  e_i  企业信息  f_s  财务统计
                    // i_m  库存管理  i_m  订单管理  p_c  工作牌  p_l  产品库  p_m  人员管理
                    let { enterpriseName, ecardName, sex, ecardPhone, ecardNote, enterpriseLevel } = res.data,
                        { a_m, c_m, e_i, f_s, i_m, p_l, p_m, o_m } = res.data.userPermission,
                        baseInfo = [{
                            "title": "企业名称",
                            "value": enterpriseName ?? ''
                        }, {
                            "title": "姓名",
                            "value": ecardName ?? ''
                        }, {
                            "title": "性别",
                            "value": sex ?? ''
                        }, {
                            "title": "手机号码",
                            "value": ecardPhone ?? ''
                        }, {
                            "title": "所属部门",
                            "value": ecardNote ? ecardNote : '未设置'
                        }],
                        powerInfo = [{
                            "title": "产品库",
                            "value": p_l == 'D' ? '不可见' : (p_l == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "客户管理",
                            "value": c_m == 'D' ? '不可见' : (c_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "企业信息",
                            "value": e_i == 'D' ? '不可见' : (e_i == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "订单管理",
                            "value": o_m == 'D' ? '不可见' : (o_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "人员管理",
                            "value": p_m == 'D' ? '不可见' : (p_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "财务统计",
                            "value": f_s == 'D' ? '不可见' : (f_s == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "库存管理",
                            "value": i_m == 'D' ? '不可见' : (i_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "权限信息",
                            "value": a_m == 'D' ? '不可见' : (a_m == 'R' ? '仅可查看' : '查看/编辑')
                        }];
                    that.WorkForm = {
                        name: ecardName,
                        sex: sex,
                        phone: ecardPhone,
                        department: ecardNote
                    }
                    that.warkCardInfo = res.data
                    that.baseInfo = baseInfo;
                    that.powerInfo = powerInfo;
                    this.enterpriseLevel = enterpriseLevel;
                    switch(enterpriseLevel){
                        case 0:
                            this.backgroundImageUrl  = require('../../../assets/supply/bannerSupplyIndex.png');
                        break;
                        case 1:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner1.png');
                        break;
                        case 2:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner2.png');
                        break;
                        case 3:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner3.png');
                        break;
                        case 4:
                            this.backgroundImageUrl  = require('../../../assets/supply/banner4.png');
                        break;
                    }
                }
            })
        },
        // 监听手机号清空
        handlePhoneEmpty() {
            this.workCardRules = workCardRulesNoPhone
        },
        // 用户修改工作牌接口
        modifyWorkCard(formName) {
            let that = this;
            if (that.WorkForm.name) {
                if (that.WorkForm.phone) {
                    that.workCardRules = workCardRulesHasPhone
                }
                that.$refs[formName].validate((valid) => {
                    if (valid) {
                        that.$http.userReviseNameCard({
                            userId: parseInt(that.$store.state.userId),
                            token: that.$getStorage('token'),
                            recordeId: that.warkCardInfo.recordeId,
                            ecardName: that.WorkForm.name,
                            ecardPhone: that.WorkForm.phone,
                            ecardNote: that.WorkForm.department,
                            sex: that.WorkForm.sex
                        }).then(res => {
                            if (res.code == 200) {
                                that.$common.message(res.msg, 'success');
                                that.dialogShow = false;
                                that.userFindNameCard();
                            }
                        })
                    }
                });
            }else{
                this.$common.message('姓名不能为空！', 'error')
            }

        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
        },
        // 员工信息-----------------------------
        // 操作者提供权限是否可操作 被操作者提供dcPermissionFlag为N(N代表不是所有者)
        enterpriseUserListDetails() {
            let that = this,
                logerEnterpriseStateInfo = that.$getStorage('enterpriseStateInfo'),//操作者权限
                logerUserId = that.$getStorage('userId');
            that.$http.enterpriseUserListDetails({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                recordeId: parseInt(that.queryId)
            }).then(res => {
                var hasData = (JSON.stringify(res.data) == "{}");
                that.hasData = hasData;
                if (!hasData && res.code == 200) {
                    // a_m 人员权限管理  c_m  客户管理  e_i  企业信息  f_s  财务统计
                    // i_m  库存管理  i_m  订单管理  p_c  工作牌  p_l  产品库  p_m  人员管理
                    let { enterpriseName, ecardName, sex, ecardPhone, ecardNote, dcPermissionFlag, userId } = res.data,
                        { a_m, c_m, e_i, f_s, i_m, p_l, p_m, o_m } = res.data.userPermission,
                        baseInfo = [{
                            "title": "企业名称",
                            "value": enterpriseName
                        }, {
                            "title": "姓名",
                            "value": ecardName
                        }, {
                            "title": "性别",
                            "value": sex
                        }, {
                            "title": "手机号码",
                            "value": ecardPhone
                        }, {
                            "title": "所属部门",
                            "value": ecardNote
                        }],
                        powerInfo = [{
                            "title": "产品库",
                            "value": p_l == '' ? '无权查看' : (p_l == 'D' ? '不可见' : (p_l == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "客户管理",
                            "value": c_m == '' ? '无权查看' : (c_m == 'D' ? '不可见' : (c_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "企业信息",
                            "value": e_i == '' ? '无权查看' : (e_i == 'D' ? '不可见' : (e_i == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "订单管理",
                            "value": o_m == '' ? '无权查看' : (o_m == 'D' ? '不可见' : (o_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "人员管理",
                            "value": p_m == '' ? '无权查看' : (p_m == 'D' ? '不可见' : (p_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "财务统计",
                            "value": f_s == '' ? '无权查看' : (f_s == 'D' ? '不可见' : (f_s == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "库存管理",
                            "value": i_m == '' ? '无权查看' : (i_m == 'D' ? '不可见' : (i_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "权限信息",
                            "value": a_m == '' ? '无权查看' : (a_m == 'D' ? '不可见' : (a_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }];
                    /**
                     * target: 被修改的用户，目标用户
                        operator：操作者
                        修改员工信息：
                        (target.userId == operator.userId) || ( (operator.p_m : w) && (target.dcPermissionFlag == N) )
                        删除员工：
                        (target.userId != operator.userId) && ( (operator.p_m : w) && (target.dcPermissionFlag == N) )
                        查看权限：
                        (target.userId == operator.userId) || (operator.a_m : w) || (operator.a_m : r)
                        修改权限：
                         (operator.a_m : w) &&  (target.dcPermissionFlag == N)
                     */
                    //查看员工信息--是否显示修改按钮
                    if ((logerUserId == userId) || (logerEnterpriseStateInfo.userPermission.p_m == 'W' && dcPermissionFlag == 'N')) {
                        that.showModifyEnter = true;
                    }
                    // 查看员工信息--是否显示删除按钮
                    if ((logerUserId != userId) && (logerEnterpriseStateInfo.userPermission.p_m == 'W' && dcPermissionFlag == 'N')) {
                        this.showDeleteEnter = true;
                    }
                    // 查看员工信息--查看权限
                    if ((logerUserId == userId) || logerEnterpriseStateInfo.userPermission.a_m == 'W' || logerEnterpriseStateInfo.userPermission.a_m == 'R') {
                        that.showPowerList = true;
                    }
                    // 查看员工信息--是否显示修改权限按钮
                    if (logerEnterpriseStateInfo.userPermission.a_m == 'W' && dcPermissionFlag == 'N') {
                        that.showPowerList = true;
                        that.showPowerEnter = true;
                    }
                    that.WorkForm = {
                        name: ecardName,
                        sex: sex,
                        phone: ecardPhone,
                        department: ecardNote
                    }
                    that.drawerForm = res.data.userPermission
                    that.warkCardInfo = res.data
                    that.baseInfo = baseInfo;
                    console.log('that.baseInfo',that.baseInfo)
                    that.powerInfo = powerInfo
                }
            })
        },
        // 删除员工
        deleteBtn() {
            let that = this;
            that.$confirm('确定删除员工？', '提示').then(() => {
                that.$http.removeEnterpriseUser({
                    userId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    recordeId: parseInt(that.queryId),
                }).then(res => {
                    that.$common.message(res.msg, 'success')
                    that.$router.go(-1)
                })
            }).catch(() => { })
        },
        // 修改权限  企业用户设置权限接口
        enterpriseUserRevisePermission() {
            let that = this;
            that.$http.enterpriseUserRevisePermission({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                recordeId: parseInt(that.queryId),
                userPermission: that.drawerForm
            }).then(res => {
                this.$common.message(res.msg, 'success')
                this.drawer = false
                this.enterpriseUserListDetails()
            })
        },
        dialogClose() {
            this.dialogShow = false;
        }
    }
}
</script>
<style lang="less" scoped>
.work-card {
    .main {
        width: 980px;

        .banner {
            // background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .infoBox {
            height: 140px;
            background: #F8F8F8;
            border-radius: 10px;
            padding: 48px 55px;

            /deep/ .el-avatar {
                width: 130px !important;
                height: 130px !important;
            }
            .sexImg {
                position: absolute;
                right: 5px;
                bottom: 5px;
                z-index: 99;
            }

            .Name_Iphone {
                height: 80px;
                width: 230px;
                margin-left: 25px;
                // padding-right: 80px;
                border-right: 1px solid #999999;
                // border: 1px solid red;

                .name {
                    margin-top: 15px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #333333;
                    // border: 1px solid rebeccapurple;
                    width: 100%;
                    word-break: break-all;
                }

                .iphone {
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .firm {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                margin-left: 80px;
                width: 180px;
                // border: 1px solid red;
            }

            .section {
                width: 90px;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                margin-left: 30px;
                // border: 1px solid red;
            }

            .editBtn {
                width: 60px;
                margin-left: 50px;
                cursor: pointer;
                // border: 1px solid red;

                img {
                    margin-right: 5px;
                }

                .text {
                    font-size: 16px;
                    font-weight: 400;
                    color: #2971FA;
                }
            }
        }

        .jurisdiction {
            margin-top: 30px;
            padding: 30px 40px;
            background: #F8F8F8;
            border-radius: 10px;

            .text {
                font-size: 24px;
                font-weight: 400;
                color: #333333;
            }

            .btn {
                border-radius: 5px;
                border: 2px solid #1785F6;
                padding: 12px 15px;
                color: #016EF2;
                font-size: 16px;
                font-weight: 400;
                cursor: pointer;

                i {
                    margin-left: 10px;
                }
            }

            .list {
                margin-top: 30px;

                .item {
                    width: 50%;
                    border-bottom: 1px solid #666666;
                    padding: 20px 0;

                    .title {
                        width: 80px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #999999;
                        margin-right: 40px;
                    }

                    .value {
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
    }
    .modify-ok {
        margin: auto;
        width: 160px;
        height: 60px;
    }
}
</style>